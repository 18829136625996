import axios from 'axios'
import qs from 'qs'
import { PRODUCT_TYPES } from '../types'

const BASE_URL = "https://cms.besomakh.com"

// const setProduct = payload => ({
//   type: PRODUCT_TYPES.SET_PRODUCT,
//   payload,
// })

const setProducts = payload => ({
  type: PRODUCT_TYPES.SET_PRODUCTS,
  payload,
})

const setProductsLoading = payload => ({
  type: PRODUCT_TYPES.SET_PRODUCTS_LOADING,
  payload
})

const setSearch = payload => ({
  type: PRODUCT_TYPES.SET_SEARCH_PRODUCT,
  payload
})

const serializeData = ({ id, attributes: product }) => {
  const images = product.Images.data || []
  const thumbnail = images[0]

  return ({
    id,
    name: product.Name,
    salePrice: product.SalePrice,
    originalPrice: product.OriginalPrice,
    ingredient: product.Ingredient,
    howToUse: product.HowToUse,
    benefits: product.Benefits,
    thumbnail: thumbnail && {
      url: `${BASE_URL}${product.Images.data[0].attributes.url}`,
      alt: product.Images.data?.[0].attributes.name,
    },
    images: images.map(image => ({
      url: `${BASE_URL}${image.attributes.url}`,
      alt: image.attributes.name,
    })),
  })
}

const getProducts = () => async dispatch => {
  dispatch(setProductsLoading(true))

  try {
    const params = {
      populate: "Images",
      pagination: {
        page: 1,
        pageSize: 50
      }
    }

    const response = await axios.get(`${BASE_URL}/api/products?sort=updatedAt:desc&${qs.stringify(params)}`)
    const products = response.data.data.map(serializeData)
    dispatch(setProducts(products))
  } catch (e) {
    dispatch(setProducts([]))
  } finally {
    setProductsLoading(false)
  }
}

const actions = {
  setSearch,
  setProducts,
  getProducts,
}

export default actions
